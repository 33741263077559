
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.input-file-box {
  width: 80%;
  height: 40%;
  .drag {
    padding: 5px;
    height: 100%;
    width: 100%;
    display: flex;

    .ant-upload-drag-icon {
      width: 50%;

      .excel-logo {
        max-height: 100px;
      }
    }
    .description-container {
      width: 50%;
      p {
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin: 0;
}

.ant-upload-list {
  background-color: white;
}