@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100vh;
  width: 100%;
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.header-home{max-height:15%}.header-home .header-logo{height:100%}.header-home .header-logo .logo-altyor{max-height:100%;max-width:100%}.header-home .header-title{display:flex;justify-content:flex-end;align-items:center;height:100%;padding-right:1%}.header-home .header-title .title-container{max-height:100%;max-width:100%;color:white;font-family:'Open Sans', sans-serif}.header-home .header-title .title-container em{font-style:normal;-webkit-text-decoration:underline #FF8900;text-decoration:underline #FF8900;text-underline-position:under;text-underline-offset:3px}

.input-file-box{width:80%;height:40%}.input-file-box .drag{padding:5px;height:100%;width:100%;display:flex}.input-file-box .drag .ant-upload-drag-icon{width:50%}.input-file-box .drag .ant-upload-drag-icon .excel-logo{max-height:100px}.input-file-box .drag .description-container{width:50%}.input-file-box .drag .description-container p{font-family:'Open Sans', sans-serif}.ant-upload.ant-upload-drag .ant-upload-drag-container{display:flex;align-items:center;width:100%;height:100%}.ant-upload.ant-upload-drag .ant-upload{padding:0}.ant-upload.ant-upload-drag p.ant-upload-drag-icon{margin:0}.ant-upload-list{background-color:white}

.upload-component{display:flex;align-items:center;height:100%;width:100%;flex-direction:column;justify-content:center}.upload-component .form-upload{display:flex;align-items:center;flex-direction:column;justify-content:space-around;height:80%;width:75%}.upload-component .button-container{display:flex;align-items:center;justify-content:space-evenly;width:75%;margin-top:1%}.upload-component .button-container .button-compare{border:none;display:flex;justify-content:center;align-items:center;background-color:#FF8900}.upload-component .button-container .button-compare p{margin:0 5px;color:white;font-family:'Open Sans', sans-serif}.upload-component .button-container .button-info{border:none;background-color:#FF8900}.upload-component .button-container .button-info p{color:white;font-family:'Open Sans', sans-serif}.upload-component .ant-switch-checked{background-color:#FF8900}.upload-component .input-file-box .drag{height:90%}

.home-page{display:flex;height:100vh;width:100%}.home-page .background{position:relative;display:flex;width:100%;height:100%;background-image:url(/static/media/SupplyChain.24ecee2f.png);background-repeat:no-repeat;background-size:cover;-webkit-filter:brightness(45%);filter:brightness(45%)}.home-page .dashboard{position:absolute;display:flex;flex-direction:column;width:100%;height:100%}

