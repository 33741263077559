

.home-page {
  display: flex;
  height: 100vh;
  width: 100%;

  .background {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/SupplyChain.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(45%);
  }

  .dashboard {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}