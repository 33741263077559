
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

$altyorColor: #FF8900;

.upload-component {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  .form-upload {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 80%;
    width: 75%;
  }
  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 75%;
    margin-top: 1%;

    .button-compare {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $altyorColor;
      p{
        margin: 0 5px;
        color: white;
        font-family: 'Open Sans', sans-serif;
      }
    }
    .button-info {
      border: none;
      background-color: $altyorColor;
      p{
        color: white;
        font-family: 'Open Sans', sans-serif;
      }
    }
  }
  .ant-switch-checked {
    background-color: $altyorColor;
  }
  .input-file-box .drag {
    height: 90%;
  }
}

